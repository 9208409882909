import React, { useContext, useEffect, useState } from "react"
import moment from "moment"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { StaticQuery, graphql } from "gatsby"
import css from "@styled-system/css"
import {
  Box,
  Button,
  Column,
  Container,
  DunkText,
  Flex,
  H1,
  PostCard,
  ProjectFilter,
  Text,
  Select
} from "components"
import {
  ActiveFilterProvider,
  ActiveFilterContext,
} from "../../contexts/project-filters"

const BlogPage = ({ data }) => {
  const [currentPosition, setCurrentPosition] = useState(0)
  const [filterBranch, setFilterBranch] = useState("All Branches")
  const [visibleBlogs, setVisibleBlogs] = useState([])
  const [size] = useState(10)

  const { activeFilters } = useContext(ActiveFilterContext)

  useEffect(() => {
    data.posts && data.posts.nodes &&
      data.posts.nodes.length > 0 &&
      setVisibleBlogs(
        data.posts.nodes
          .filter(blog => {
            if (activeFilters.categories.length > 0) {
              return activeFilters.categories.every(filterCategory => {
                let foundCategory = false
                blog.blogCategories.forEach(category => {
                  if (filterCategory === category.blogCategoryName) {
                    foundCategory = true
                  }
                })
                return foundCategory
              })
            } else {
              return true
            }
          })
          .filter(blog => {
            if (filterBranch !== "All Branches") {
              if (blog.branch && blog.branch.name === filterBranch) {
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          })
      )
  }, [activeFilters, data.posts, filterBranch])

  return (
    <>
      <HelmetDatoCms seo={data.page.seoMetaTags}>
        <meta name="keywords" content={data.page.seoKeywords} />
      </HelmetDatoCms>
      {/* INTRO */}
      <Box as="section" pt={[150, 170]} bg="muted">
        <Container pt={[5, 6]} pb={[3, 4]}>
          <Column left>
            <H1 as="h1">
              <DunkText lines={data.page.title.split("\n")} />
            </H1>
            {data.page.subtitle && (
              <Text
                children={data.page.subtitle}
                mt={[3, 4]}
                fontFamily="condensed"
                fontSize={[5, 6]}
              />
            )}
          </Column>
        </Container>
        <Box as="section" pt={0} pb={3}>
          <Container>
            <div css={css({ display: ["block", "flex"] })}>
              <div css={css({ flex: "0 1 70%", mt: [3, 3] })}>
                <ProjectFilter
                  type={"categories"}
                  filterNodes={data.blogCategories.nodes.map(item => ({ name: item.blogCategoryName })).sort((a, b) => {
                    if (a.name < b.name) {
                      return -1
                    } else if (a.name > b.name) {
                      return 1
                    } else {
                      return 0
                    }
                  })}
                />
              </div>
              <div css={css({ flex: "0 1 30%", mt: [4, 0], mb: [4] })}>
                <Text
                  children={"Filter by branch"}
                  mt={0}
                  fontFamily="condensed"
                  fontSize={[4, 5]}
                  css={css({ mt: 0, mb: 2 })}
                />
                <div>
                  <Select
                    value={filterBranch}
                    onChange={event => {
                      setFilterBranch(event.target.value)
                    }}
                    background="white"
                  >
                    <option>All Branches</option>
                    {data.branches &&
                      data.branches.nodes
                        .sort((a, b) => {
                          if (a.name < b.name) {
                            return -1
                          } else if (a.name > b.name) {
                            return 1
                          } else {
                            return 0
                          }
                        })
                        .map((branch, index) => {
                          return (
                            <option key={`${branch}-${index}`}>
                              {branch.name}
                            </option>
                          )
                        })}
                  </Select>
                </div>
              </div>
            </div>
          </Container>
        </Box>
        {/* POSTS */}
        <Box bg="muted" position="relative" pb={[5, 6]}>
          <Container>
            <Flex
              pt={4}
              m={-3}
              flexWrap="wrap"
              css={css({ overflow: "hidden" })}
            >
              {visibleBlogs &&
                visibleBlogs.length > 0 &&
                visibleBlogs
                  .slice(0, size * (currentPosition + 1))
                  .map((post, index) => (
                    <PostCard
                      width={
                        (index % 5 === 0 || index % 5 === 1) && [
                          "100%",
                          null,
                          1 / 2,
                          1 / 2,
                        ]
                      }
                      image={post.image && post.image.url}
                      date={
                        post.date &&
                        moment(post.date).format("MMM DD, YYYY")
                      }
                      title={post.title}
                      subtitle={post.subtitle}
                      link={"/blog/" + post.url + "/"}
                      key={"blogPost" + post.url}
                    />
                  ))}
            </Flex>
            {visibleBlogs &&
              visibleBlogs.length > size * (currentPosition + 1) && (
                <Flex justifyContent="center" mt={5}>
                  <Button
                    children={"See more"}
                    onClick={() => {
                      setCurrentPosition(currentPosition + 1)
                    }}
                  />
                </Flex>
              )}
          </Container>
        </Box>
      </Box>
    </>
  )
}

export const query = graphql`
  query BlogPageQuery {
    page: datoCmsBlogPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      seoKeywords
      title
      subtitle
    }
    
    posts: allDatoCmsBlogPost(
      filter: { featured: { eq: true } }
      sort: { order: DESC, fields: postedDate }
    ) {
      nodes {
        url
        image {
          url
        }
        title
        postedDate
        subtitle
        blogCategories {
          blogCategoryName
        }
        branch {
          name
        }
      }
    }

    blogCategories: allDatoCmsBlogCategory {
      nodes {
        blogCategoryName
      }
    }

    branches: allDatoCmsBranch {
      nodes {
        name
      }
    }
  }
`

const withContext = Component => {
  return props => {
    return (
      <ActiveFilterProvider>
        <Component {...props} />
      </ActiveFilterProvider>
    )
  }
}

export default withContext(BlogPage)
